
// import * as React from 'react';

// axios
import axios, { AxiosError } from "axios";

// JWT
import * as jose from 'jose'


// JWT検証
export const jwt_verify = async (jwk, alg, jwt) => {
    const publicKey = await jose.importJWK(jwk, alg)
    const jwtVerify = await jose.jwtVerify(jwt, publicKey)
    const result = await jwtVerify;
    const payload = result["payload"]
    return payload
}

// Axios Error
interface ErrorData {
    message: string;
    status: number;
    error: string;
}



// 呼び出し元での使用例
export const callAuth = async (jwt: string) => {
    const result = await Auth(jwt);
    // console.log("Auth result:", result);
    return result;
};

export const Auth = async (jwt) => {
    try {
        // 公開鍵取得
        const pub = await axios.get("https://ipfuolzg5c.execute-api.ap-northeast-1.amazonaws.com/example-jwt/.well-known/jwks.json");
        const alg = pub.data.alg;
        const jwk = {
            kty: pub.data.kty,
            n: pub.data.n,
            e: pub.data.e,
        };

        // JWT検証
        try {
            const payload = await jwt_verify(jwk, alg, jwt);
            // console.log("jwt_verify successful:", payload);
            return payload;
        } catch (error) {
            // 非同期処理が失敗した場合
            console.error('JWT verification failed:', error);
            return false;
        }
    } catch (e) {
        const error = e as AxiosError<ErrorData>;
        if (!error.response) {
            console.error("undefinedError:", error);
            return false;
        }
        console.error("Axios error:", error, "message:", error.response.data.message);
        return false;
    }
};

