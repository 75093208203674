
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import './SettingComponent.css'

// @mui/material/
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import languageIcon from '../../assets/images/language_white.png';
import maintenanceIcon from '../../assets/images/MasterInput_white.png';
import manualIcon from '../../assets/images/Manual_white.png';

// Setting
import SettingsIcon from '@mui/icons-material/Settings';
import { Grid } from '@mui/material';


export const SettingComponent = (props) => {
    // console.log("props", props)

    // Navigation--------------------------------------------------------------------
    const navigate = useNavigate();
    const handleClickMasterInput = () => {
        navigate('/MasterInput/', { state: { MasterInput: "MasterInput" } });
        setOpenTest(false)
        handleNoMouseOutChildMasterInput()
        handleNoMouseOutChildLanguage()
    };

    // Setting--------------------------------------------------------------------
    // 親メニュー
    const [openTest, setOpenTest] = React.useState(false);

    // 子メニュー
    const [openChildMasterInput, setOpenChildMasterInput] = React.useState(false);
    const handleMouseOverChildMasterInput = () => { setOpenChildMasterInput(true) }
    const handleNoMouseOutChildMasterInput = () => { setOpenChildMasterInput(false) }
    // const handleClickChildMasterInput = () => {
    //     setOpenTest(false)
    //     handleNoMouseOutChildMasterInput()
    //     handleNoMouseOutChildLanguage()
    //     handleNoMouseOutChildManual()
    // }

    const [openChildLanguage, setOpenChildLanguage] = React.useState(false);
    const handleMouseOverChildLanguage = () => { setOpenChildLanguage(true) }
    const handleNoMouseOutChildLanguage = () => { setOpenChildLanguage(false) }
    const handleClickChildLanguage = () => {
        setOpenTest(false)
        handleNoMouseOutChildMasterInput()
        handleNoMouseOutChildLanguage()
        handleNoMouseOutChildManual()
    }

    const [openChildManual, setOpenChildManual] = React.useState(false);
    const handleMouseOverChildManual = () => { setOpenChildManual(true) }
    const handleNoMouseOutChildManual = () => { setOpenChildManual(false) }
    const handleClickChildManual = () => {
        setOpenTest(false)
        handleNoMouseOutChildMasterInput()
        handleNoMouseOutChildLanguage()
        handleNoMouseOutChildManual()
    }

    const parentLeft = "-110px"
    const parentTop = "40px"
    const parentWidth = "187px"
    const parentFontSize = "13px"
    const childFontSize = "13px"

    const hoverColor = "#777D86"
    const firstColor = "#313741"
    const secondColor = "#3F454F"

    return (
        <>
            <div className="square" >
                <SettingsIcon sx={{ position: "absolute", top: "2px", left: "2px", width: "22px", height: "22px", cursor: "pointer" }} onClick={() => setOpenTest(!openTest)} />
                {openTest === true ?
                    <div className='box-visible'>
                        <Stack direction={"column"}>
                            {/* User */}
                            <Stack direction={"column"} sx={{ position: "relative", top: parentTop, left: parentLeft, width: parentWidth, height: "60px", bgcolor: firstColor }}>
                                <Typography sx={{ position: "absolute", top: "5px", left: "10px", width: "150px", height: "15px", fontSize: parentFontSize }} >{props.company_id}</Typography>
                                <Typography sx={{ position: "absolute", top: "20px", left: "10px", width: "150px", height: "15px", fontSize: parentFontSize }} >{props.group_id}</Typography>
                                <Typography sx={{ position: "absolute", top: "35px", left: "10px", width: "150px", height: "15px", fontSize: parentFontSize }} >{props.user_id}</Typography>
                            </Stack>
                            {/* Language */}
                            <Stack direction={"column"} sx={{ position: "relative", top: parentTop, left: parentLeft, width: parentWidth, height: "40px" }}>
                                <Box onMouseOver={handleMouseOverChildLanguage} onMouseOut={handleNoMouseOutChildLanguage}>
                                    <Grid container sx={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", color: "white", bgcolor: secondColor, ":hover": { bgcolor: hoverColor, color: "white" }, cursor: "pointer" }}>
                                        <Grid item xs={2} sx={{ width: "100%", height: "100%" }} >
                                            <Stack sx={{ width: "100%", height: "100%" }} alignItems={"center"} justifyContent={"center"}>
                                                <img src={languageIcon} alt="logo" style={{ width: "16px", height: "16px" }} />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={10} sx={{ width: "100%", height: "100%" }}>
                                            <Typography sx={{ position: "absolute", top: "10px", left: "32px", fontSize: parentFontSize }} >
                                                Language
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {openChildLanguage ?
                                        <Box sx={{ position: "absolute", top: "0px", left: "-150px" }}>
                                            <Stack direction={"column"}>
                                                <Box sx={{ width: "150px", height: "40px", bgcolor: firstColor, ":hover": { bgcolor: hoverColor, color: "white" }, cursor: "pointer" }} onClick={() => { handleClickChildLanguage() }}>
                                                    <Typography sx={{ position: "absolute", top: "10px", left: "10px", fontSize: childFontSize }} >
                                                        Engilish
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </Box>
                                        :
                                        ""
                                    }
                                </Box>
                            </Stack>
                            {/* Manual */}
                            <Stack direction={"column"} sx={{ position: "relative", top: parentTop, left: parentLeft, width: parentWidth, height: "40px" }}>
                                <Box onMouseOver={handleMouseOverChildManual} onMouseOut={handleNoMouseOutChildManual}>
                                    <Grid container sx={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", color: "white", bgcolor: firstColor, ":hover": { bgcolor: hoverColor, color: "white" }, cursor: "pointer" }}>
                                        <Grid item xs={2} sx={{ width: "100%", height: "100%" }} >
                                            <Stack sx={{ width: "100%", height: "100%" }} alignItems={"center"} justifyContent={"center"}>
                                                <img src={manualIcon} alt="logo" style={{ width: "15px", height: "15px" }} />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={10} sx={{ width: "100%", height: "100%" }}>
                                            <Typography sx={{ position: "absolute", top: "10px", left: "32px", fontSize: parentFontSize }} >
                                                Manual
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {openChildManual ?
                                        <Box sx={{ position: "absolute", top: "0px", left: "-150px" }}>
                                            <Stack direction={"column"}>
                                                <Box sx={{ width: "150px", height: "40px", bgcolor: firstColor, ":hover": { bgcolor: hoverColor, color: "white" }, cursor: "pointer" }} onClick={() => { handleClickChildManual() }}>
                                                    <Typography sx={{ position: "absolute", top: "10px", left: "10px", fontSize: childFontSize }} >
                                                        Engilish
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </Box>
                                        :
                                        ""
                                    }
                                </Box>
                            </Stack>
                            {/* Maintenance */}
                            <Stack direction={"column"} sx={{ position: "relative", top: parentTop, left: parentLeft, width: parentWidth, height: "40px" }}>
                                <Box onMouseOver={handleMouseOverChildMasterInput} onMouseOut={handleNoMouseOutChildMasterInput}>
                                    <Grid container sx={{ position: "absolute", top: "0px", left: "0px", width: "100%", height: "100%", color: "white", bgcolor: secondColor, ":hover": { bgcolor: hoverColor, color: "white" }, cursor: "pointer" }}>
                                        <Grid item xs={2} sx={{ width: "100%", height: "100%" }} >
                                            <Stack sx={{ width: "100%", height: "100%" }} alignItems={"center"} justifyContent={"center"}>
                                                <img src={maintenanceIcon} alt="logo" style={{ width: "10px", height: "18px" }} />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={10} sx={{ width: "100%", height: "100%" }}>
                                            <Typography sx={{ position: "absolute", top: "10px", left: "32px", fontSize: parentFontSize }} >
                                                Maintenance
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    {openChildMasterInput && props.role === "admin" ?
                                        <Box sx={{ position: "absolute", top: "0px", left: "-150px" }}>
                                            <Stack direction={"column"}>
                                                <Box sx={{ width: "150px", height: "40px", bgcolor: firstColor, ":hover": { bgcolor: hoverColor, color: "white" }, cursor: "pointer" }} onClick={() => handleClickMasterInput()}>
                                                    <Typography sx={{ position: "absolute", top: "10px", left: "10px", fontSize: childFontSize }} >
                                                        Master Input
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </Box>
                                        :
                                        ""
                                    }
                                </Box>
                            </Stack>
                        </Stack>
                    </div>
                    :
                    <div className='box-hidden'></div>
                }
            </div >
        </>
    );
}