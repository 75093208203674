
// @mui/material/
import { createTheme } from '@mui/material/styles';

export const SelectTheme = createTheme({
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: "white",
                    "&:nth-of-type(odd)": {
                        backgroundColor: "#313741",
                        height: "45px"
                    },
                    "&:nth-of-type(even)": {
                        backgroundColor: "#3F454F",
                        height: "45px"
                    },
                    "&.Mui-selected": {
                        backgroundColor: "#777D86",
                        "&:hover": { backgroundColor: "#777D86" }
                    },
                    "&:hover": { backgroundColor: "#777D86" },
                }
            }
        }
    }
});
