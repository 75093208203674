
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import axios, { AxiosError } from "axios";

import './VesselList.css'; // スタイルのために追加
import { SelectTheme } from '../../components/theme/SelectTheme';
import { Main, BootstrapInput } from '../../components/styled/styled';
import { callAuth } from '../../components/components/auth';
import { SearchContext } from '../../App';
import { MyAppBar } from '../../components/components/AppBar';
import { TabComponent } from '../../components/components/TabComponent';
import { SettingComponent } from '../../components/components/SettingComponent';
import NoReceived_svg from '../../assets/images/no-receiived.svg';

// @mui/material/
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

// @mui/icons-material/
import StarIcon from '@mui/icons-material/Star';

// Dialog
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

interface payload {
  role: string | null
  user_id: string | null
  group_id: string | null
  company_id: string | null
  gid: string | null
  gidList: Array<string>
  imoList: Array<string>
  jwt: string | null
}

// Axios Error
interface ErrorData {
  message: string;
  status: number;
  error: string;
}


const ciiSize = "18px"
const spconSize = "18px"
export default function BasicTable({ setSeachInfo }) {

  // 初期表示時に、WADATSUMI認証で、ユーザIDを取得。ユーザIDを元に、SPM側で保持しているグループマスタを元にどこのグループ所属か抽出し、GroupStateにセットする。
  const [message, setMessage] = React.useState<string>();
  let init = true
  const [userCheck, setUserCheck] = React.useState(false);
  const [user_id, setUser_id] = React.useState<string>();
  const [group_id, setGroup_id] = React.useState<string>();
  const [company_id, setCompany_id] = React.useState<string>();
  const [jwt, setJwt] = React.useState<string>();
  const [role, setRole] = React.useState<string>();

  // Loading--------------------------------------------------------------------
  const [loading, setLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);

  React.useEffect(() => {
    console.log("vessellist")
    if (init === true) {
      let jwt_ls
      let role_ls
      if (localStorage.getItem("payload")) {
        const pay_json = localStorage.getItem("payload")
        if (pay_json !== null) {
          const pay = JSON.parse(pay_json)
          role_ls = pay["role"]
          jwt_ls = pay["jwt"]
          setJwt(jwt_ls)
          setRole(role_ls)
        }
      }

      // 例としてJWTを渡して呼び出す
      callAuth(jwt_ls).then(result => {
        if (result) {
          console.log("認証成功[VesselList]");

          let url = ""
          let sub = result["sub"]

          // ブラウザ更新による初期表示か、他画面から遷移の初期表示か判定。他画面から遷移の初期表示の場合、SeachInfo.gidは既にセットされている。
          if (SeachInfo.gid === "") {
            url = `${base_url}%23?user=${sub}&init=${init}`
          }
          else {
            url = `${base_url}%23?user=${sub}&GID=${SeachInfo.gid}`
          }
          console.log("init_url", url)
          setMessage("Authentication success.")
          getVesselList(url, jwt_ls, role_ls)
        } else {
          console.log("[VesselList]認証失敗");
          setMessage("Authentication error.")
        }
      });
    }
    init = false

  }, [init])


  // コンテクスト取得--------------------------------------------------------------------
  const SeachInfo: any = React.useContext(SearchContext);

  const [base_url, setBase_url] = React.useState(`${process.env.REACT_APP_API_URL}/VesselList/`);

  // Navigation--------------------------------------------------------------------
  const navigate = useNavigate();
  const handleClickEmissionBoard = (index, imoNo, from, to, unit) => {
    setSeachInfo({ imoNo: imoNo, from: from, to: to, gid: SeachInfo.gid, gidList: SeachInfo.gidList, imoList: SeachInfo.imoList })
    navigate('/EmissionBoard/', { state: { unit: unit } });
  };
  const handleClickSpeedConsumption = (index, imoNo, from, to) => {
    console.log("imoNo", imoNo)
    setSeachInfo({ imoNo: imoNo, from: from, to: to, gid: SeachInfo.gid, gidList: SeachInfo.gidList, imoList: SeachInfo.imoList })
    navigate('/SpeedConsumption/', { state: { beaufort: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], BallastLaden: "All", fromLogSpeed: "5", toLogSpeed: "23" } });
  };
  const handleClickFacorite = (imo, favorite) => {
    setLoading(true)
    setSuccess(false)
    const url = `${base_url}%23?&user=${user_id}&favorite=${favorite}&GID=${SeachInfo.gid}&imo=${imo}`
    getVesselList(url, jwt, role)
  };

  // WADATSUMIログイン画面遷移----------------------------------------------------------------------------------------------------------------------------
  const handleClickWADATSUMI = () => {
    window.location.replace(`${process.env.REACT_APP_WADATSUMIURL}`)
  };

  // Group--------------------------------------------------------------------
  const selectGroup = (event: SelectChangeEvent) => {
    setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: SeachInfo.from, to: SeachInfo.to, gid: `${event.target.value as string}`, gidList: SeachInfo.gidList, imoList: SeachInfo.imoList })
    onClickGroup()
  };
  const selectGroupAction = (gid) => {
    setLoading(true)
    setSuccess(false)
    const url = `${base_url}%23?&user=${user_id}&GID=${gid}`
    getVesselList(url, jwt, role)
  }

  const [changeClickGroup, setChangeClickGroup] = React.useState<number>(0);

  const onClickGroup = () => {
    setChangeClickGroup((prev) => (prev + 1));
  }
  React.useEffect(() => {
    if (changeClickGroup > 0) {
      selectGroupAction(SeachInfo.gid)
    }
  }, [changeClickGroup])


  // VesselList取得--------------------------------------------------------------------
  const [rows, setRows] = React.useState<any>([])
  const getVesselList = (url, jwt, role) => {
    console.log("url:", url);

    const options = {
      headers: {
        'Authorization': jwt,
      }
    }
    axios.get(url, options).then((res) => {
      console.log("axios[VesselList]", res)

      setSeachInfo({ imoNo: `${SeachInfo.imoNo}`, from: SeachInfo.from, to: SeachInfo.to, gid: res.data.datas.gid, gidList: res.data.datas.gidList, imoList: res.data.datas.imoList })

      setUser_id(res.data.datas.user_id)
      setGroup_id(res.data.datas.group_id)
      setCompany_id(res.data.datas.company_id)

      if (role !== undefined) {
        localStorage.clear()
        let Payload: payload
        Payload = {
          role: role,
          user_id: res.data.datas.user_id,
          group_id: res.data.datas.group_id,
          company_id: res.data.datas.company_id,
          gid: res.data.datas.gid,
          gidList: res.data.datas.gidList,
          imoList: res.data.datas.imoList,
          jwt: jwt
        }
        localStorage.setItem("payload", JSON.stringify(Payload))
      }
      setRows(res.data.datas.rows)
      setUserCheck(false)

      // Loading解除
      setLoading(false)
      setSuccess(true)
    })
      .catch((e: AxiosError<ErrorData>) => {
        setLoading(false)
        setSuccess(true)
        if (e.response === undefined) {
          console.log("undefinedError: " + e);
          return;
        }
        console.log(e + ", message: " + e.response.data.message);
      });
  }

  type ActiveSortNo = {
    item: string;
    seq: number;
    sort: string;
  }
  // ソート機能--------------------------------------------------------------------
  const [activeSortNo, setActiveSortNo] = React.useState<ActiveSortNo>({ item: '', seq: 0, sort: '' }); // seq: 文字列→1, 数値→2
  const sortAsc = (item: string, seq: number) => { //昇順に並び替え
    const newListData = [...rows];
    if (seq === 1) { //文字列
      newListData.sort((a, b) => {
        if (a[item] > b[item]) {
          return 1;
        } else {
          return -1;
        }
      })
    } else { //数値
      newListData.sort((a, b) => {
        return a[item] - b[item];
      })
    }


    if (activeSortNo.item === item && activeSortNo.sort === 'asc') { //ソート解除
      setActiveSortNo({ item: '', seq: 0, sort: 'asc' });
    } else {
      setActiveSortNo({ item, seq, sort: 'asc' });
    }
    setRows(newListData);
  }

  const sortDesc = (item: string, seq: number) => { //降順に並び替え
    const newListData = [...rows];
    if (activeSortNo.item === item && activeSortNo.sort === 'desc') { //ソート解除
      if (seq === 1) { //文字列
        newListData.sort((a, b) => {
          if (a[item] > b[item]) {
            return 1;
          } else {
            return -1;
          }
        })
      } else { //数値
        newListData.sort((a, b) => {
          return a[item] - b[item];
        })
      }
      setActiveSortNo({ item: '', seq: 0, sort: 'desc' });
    } else {
      if (seq === 1) { //文字列
        newListData.sort((a, b) => {
          if (a[item] < b[item]) {
            return 1;
          } else {
            return -1;
          }
        })
      } else { //数値
        newListData.sort((a, b) => {
          return b[item] - a[item];
        })
      }
      setActiveSortNo({ item, seq, sort: 'desc' });
    }
    setRows(newListData);
  }

  // 現在の日付を取得
  const currentDate = new Date();

  // 7日前の日付を計算
  const beforeday = new Date(currentDate);
  beforeday.setDate(currentDate.getDate() - 4);


  return (
    <Box height="100vh" sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* useId有効期限切れダイアログ */}
      <Dialog
        open={userCheck}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "600px",
              height: "100%",
              maxHeight: "240px",
            },
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: "#9ea2ad",
            height: "50px",
            color: "white",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
          }}>
          UserId Update
        </DialogTitle>
        <DialogContent
          sx={{
            background: "linear-gradient(#40475d, #0d1532)",
          }}>
          <Typography align="left" sx={{ marginTop: "32px", color: 'white', fontSize: 13, fontFamily: 'Roboto Condensed', fontWeight: '400', wordWrap: 'break-word' }}>
            {message === "" ? "" : message}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ height: "50px", bgcolor: "#9ea2ad" }}>
          <Button
            sx={{
              marginLeft: "0px",
              marginRight: "-8px",
              backgroundColor: "#e66300",
              width: "127px",
              height: "50px",
              padding: "0",
              fontSize: "18px",
              fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
              fontWeight: "bold",
              color: "white",
              borderRadius: "0px",
              ":hover": {
                bgcolor: "#FFFFFF",
                color: "#40475D",
              },
              textTransform: 'none'
            }}
            onClick={handleClickWADATSUMI}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* menu bar */}
      <MyAppBar company_id={company_id} group_id={group_id} user_id={user_id} role={role} />

      {/* メイン部分 */}
      <Main>

        {/* 検索フォーム */}
        <Box sx={{ height: "5%", marginTop: "32px", marginLeft: "32px", marginRight: "32px" }}>
          <Grid container spacing={0} sx={{ height: "100%" }}>
            <Grid item xs={3} sx={{ height: "100%" }}>
              <Stack direction="row" sx={{ height: "100%", marginTop: "16px" }} alignItems={"center"} justifyContent={"flex-start"}>
                <Typography align='left' sx={{ marginRight: "16px", color: 'white', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                  Group
                </Typography>
                <ThemeProvider theme={SelectTheme}>
                  <FormControl>
                    <Select
                      IconComponent={() =>
                        <div style={{ position: "absolute", top: "20px", left: "235px" }}>
                          <InputAdornment position="start" disablePointerEvents>
                            <Box sx={{ borderTop: "solid 2px rgb(255, 255, 255, 0.7)", borderRight: "solid 2px rgb(255, 255, 255, 0.7)", width: "8px", height: "8px", transform: "rotate(135deg)" }}></Box>
                          </InputAdornment>
                        </div>
                      }
                      MenuProps={{
                        MenuListProps: {
                          disablePadding: true,

                        },
                        style: { zIndex: 100000, }
                      }}
                      sx={{
                        width: 265,
                        height: 45,
                        color: "white",
                        borderRadius: 30,
                      }}
                      input={<BootstrapInput />}
                      onChange={selectGroup}
                      value={SeachInfo.gid}
                    >
                      {SeachInfo.gidList.map((gid) => {
                        return (
                          <MenuItem key={gid} value={gid}>{gid}</MenuItem>
                        )
                      }
                      )}
                    </Select>
                  </FormControl>
                </ThemeProvider>
              </Stack>
            </Grid>
            <Grid item xs={9}>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ height: "84%", marginLeft: "32px", marginRight: "32px" }}>
          <Box style={{ width: '100%', height: '5%', position: 'relative', marginTop: "32px" }}>
            <Box style={{ width: "380px", height: "26px", left: "560px", top: "10px", position: 'absolute', background: 'rgba(72, 77, 95, 0.50)', }}>
              <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                Speed Consumption Alarm
              </Typography>
            </Box>
            <Box style={{ width: "400px", height: "26px", left: "1060px", top: "10px", position: 'absolute', background: 'rgba(72, 77, 95, 0.50)', }}>
              <Typography align="center" sx={{ marginTop: "2px", color: 'rgba(255, 255, 255, 0.50)', fontSize: 14, fontFamily: 'Roboto', fontWeight: '700', wordWrap: 'break-word' }}>
                CII Score
              </Typography>
            </Box>
          </Box>

          <div className="vessel-list-wrapper">
            <div className="vessel-list-area-wrapper">
              <table className="vessel-list-table">
                <thead>
                  <tr>
                    <th className="favorite">
                      <span>
                        {/* <Box sx={{ width: "100px" }}></Box> */}
                      </span>
                    </th>
                    <th className="vesselName">
                      <span>Vessel Name</span>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <span className={(activeSortNo.item === "vesselName" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('VesselName', 1)}></span>
                          <span className={(activeSortNo.item === "vesselName" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('VesselName', 1)}></span>
                        </div>
                      </div>
                    </th>
                    <th className="latestUpdate">
                      <span>Latest Update (UTC)</span>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <span className={(activeSortNo.item === "latestUpdate" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('LatestUpdate', 1)}></span>
                          <span className={(activeSortNo.item === "latestUpdate" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('LatestUpdate', 1)}></span>
                        </div>
                      </div>
                    </th>
                    <th className="padding1">
                      <span></span>
                    </th>
                    <th className="CPCurve">
                      <span>Over CP Curve </span><br />
                      <span>for 30days [time(s)]</span>
                      <div className="common-sort-container" style={{ position: "absolute", top: "20px", left: "145px" }}>
                        <div className="common-sort-icon">
                          <span className={(activeSortNo.item === "cp" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('cp', 0)}></span>
                          <span className={(activeSortNo.item === "cp" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('cp', 0)}></span>
                        </div>
                      </div>
                    </th>
                    <th className="ReferenceCurve">
                      <span>Over Reference Curve </span><br />
                      <span>for 30days [time(s)]</span>
                      <div className="common-sort-container" style={{ position: "absolute", top: "20px", left: "155px" }}>
                        <div className="common-sort-icon">
                          <span className={(activeSortNo.item === "rf" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('rf', 0)}></span>
                          <span className={(activeSortNo.item === "rf" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('rf', 0)}></span>
                        </div>
                      </div>
                    </th>
                    <th className="padding2">
                      <span></span>
                    </th>
                    <th className="Last4Weeks">
                      <span>Last 4Weeks</span>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <span className={(activeSortNo.item === "oneMonth" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('oneMonth', 1)}></span>
                          <span className={(activeSortNo.item === "oneMonth" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('oneMonth', 1)}></span>
                        </div>
                      </div>
                    </th>
                    <th className="ThisYear">
                      <span>This Year</span>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <span className={(activeSortNo.item === "Januarytonow" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('Januarytonow', 1)}></span>
                          <span className={(activeSortNo.item === "Januarytonow" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('Januarytonow', 1)}></span>
                        </div>
                      </div>
                    </th>
                    <th className="LastYear">
                      <span>Last Year</span>
                      <div className="common-sort-container">
                        <div className="common-sort-icon">
                          <span className={(activeSortNo.item === "LastYear" && activeSortNo.sort === "asc") ? "sort-active-ascending" : "common-sort-icon-ascending"} onClick={() => sortAsc('LastYear', 1)}></span>
                          <span className={(activeSortNo.item === "LastYear" && activeSortNo.sort === "desc") ? "sort-active-descending" : "common-sort-icon-descending"} onClick={() => sortDesc('LastYear', 1)}></span>
                        </div>
                      </div>
                    </th>
                    <th className="padding3">
                      <span></span>
                    </th>
                  </tr>
                  <tr></tr>
                </thead>
                {success ?
                  <tbody>
                    {rows.length !== 0 ?
                      <>
                        {
                          rows.map((element, index) => {
                            return (
                              <tr key="">
                                <td className="favorite">
                                  <IconButton
                                    onClick={() => handleClickFacorite(element.imo, element.favorite)}
                                    sx={{
                                      fontFamily: "'Roboto Bold', 'Meiryo', sans-serif",
                                      fontWeight: "bold",
                                      color: `${element.favorite === "checked" ? "yellow" : "grey"}`,
                                      ":hover": {
                                        color: `${element.favorite === "checked" ? "orange" : "pink"}`,
                                      }
                                    }}
                                  >
                                    <StarIcon sx={{ width: "17px", height: "22px", opacity: 0.6 }} />
                                  </IconButton>
                                </td>
                                <td className="vesselName">
                                  <Stack>
                                    <Typography align='left' sx={{ color: '#ffffff', width: "100%", fontSize: "15px", fontFamily: 'RobotoRegular' }}>{element.VesselName}</Typography>
                                  </Stack>
                                </td>
                                <td className="latestUpdate">
                                  <Grid container sx={{ height: "100%" }} alignItems={"center"}>
                                    <Grid item xs={6} >
                                      <Stack>
                                        <Typography align='left' sx={{ color: '#ffffff', width: "100%", fontSize: "15px", fontFamily: 'RobotoRegular' }}>{element.LatestUpdate}</Typography>
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={6} >
                                      {new Date(element.LatestUpdate) < beforeday ?
                                        <Stack sx={{ width: "100%", marginLeft: "8px" }} alignItems={"left"}>
                                          <img src={NoReceived_svg} alt="iconLike" style={{ height: "20px", width: "20px" }} />
                                        </Stack>
                                        :
                                        ""
                                      }
                                    </Grid>
                                  </Grid>
                                </td>
                                <td className="padding1">
                                  <span></span>
                                </td>
                                <td className="CPCurve">
                                  <Button
                                    onClick={() => handleClickSpeedConsumption(index, element.imo, element.cp_from, element.cp_to)}
                                    sx={{
                                      width: "100%",
                                      height: "100%",
                                      ":hover": {
                                        bgcolor: "rgba(255, 255, 255, 0.5)",
                                        color: "orange",
                                      }
                                    }}
                                    disabled={element.oneMonth_count === "0" ? true : false}
                                  >
                                    <Grid item xs={6} sx={{ width: "100%" }}>
                                      <Stack>
                                        {element.oneMonth_count !== "0" ?
                                          <Typography align='right' sx={{ color: '#ffffff', width: "100%", fontSize: spconSize, fontFamily: 'RobotoRegular' }}>{element.cp}</Typography>
                                          :
                                          <>
                                            <Typography align='right' sx={{ color: 'rgba(255,255,255,0.6)', width: "100%", fontSize: "12px", fontFamily: 'Roboto Regular', textTransform: 'none' }}>No Data</Typography>
                                          </>
                                        }
                                      </Stack>
                                    </Grid>
                                    <Grid item xs={6} sx={{ width: "100%" }}>
                                      {parseInt(element.cp) >= 1 ?
                                        <Stack sx={{ marginLeft: "8px", width: "100%" }} alignItems={"left"}>
                                          <ReportProblemIcon sx={{ height: "17px", width: "21px", color: "yellow" }} />
                                        </Stack>
                                        :
                                        ""
                                      }
                                    </Grid>
                                  </Button>
                                </td>
                                <td className="ReferenceCurve">
                                  <Stack direction={"row"} sx={{ width: "100%", height: "100%", }}>
                                    <Button
                                      onClick={() => handleClickSpeedConsumption(index, element.imo, element.rf_from, element.rf_to)}
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        ":hover": {
                                          bgcolor: "rgba(255, 255, 255, 0.5)",
                                          color: "orange",
                                        },
                                      }}
                                      disabled={element.oneMonth_count === "0" ? true : false}
                                    >
                                      <Grid item xs={6} sx={{ width: "100%" }}>
                                        <Stack>
                                          {element.oneMonth_count !== "0" ?
                                            <Typography align='right' sx={{ color: '#ffffff', width: "100%", fontSize: spconSize, fontFamily: 'RobotoRegular' }}>{element.rf}</Typography>
                                            :
                                            <>
                                              <Typography align='right' sx={{ color: 'rgba(255,255,255,0.6)', width: "100%", fontSize: "12px", fontFamily: 'Roboto Regular', textTransform: 'none' }}>No Data</Typography>
                                            </>
                                          }
                                        </Stack>
                                      </Grid>
                                      <Grid item xs={6} sx={{ width: "100%" }}>
                                        {parseInt(element.rf) >= 5 ?
                                          <Stack sx={{ marginLeft: "8px", width: "100%" }} alignItems={"left"}>
                                            <ReportProblemIcon sx={{ height: "17px", width: "21px", color: "yellow" }} />
                                          </Stack>
                                          :
                                          <></>
                                        }
                                      </Grid>
                                    </Button>
                                  </Stack>
                                </td>
                                <td className="padding2">
                                  <span></span>
                                </td>
                                <td className="Last4Weeks">
                                  <Stack direction={"row"} sx={{ width: "100%", height: "100%", }}>
                                    <Button
                                      onClick={() => handleClickEmissionBoard(index, element.imo, element.oneMonth_from, element.oneMonth_to, "Weekly")}
                                      sx={{
                                        width: "100%",
                                        height: "100%",
                                        ":hover": {
                                          bgcolor: "rgba(255, 255, 255, 0.5)"
                                        }
                                      }}
                                      disabled={element.oneMonth === "" ? true : false}
                                    >
                                      {
                                        element.oneMonth === "A" ?
                                          <>
                                            <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(0, 126, 58, 0.3)" }}>
                                              <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                {element.oneMonth}
                                              </Typography>
                                            </Stack>
                                          </>
                                          :
                                          <>
                                            {
                                              element.oneMonth === "B" ?
                                                <>
                                                  <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(39, 172, 101, 0.3)" }}>
                                                    <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                      {element.oneMonth}
                                                    </Typography>
                                                  </Stack>
                                                </>
                                                :
                                                <>
                                                  {
                                                    element.oneMonth === "C" ?
                                                      <>
                                                        <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(218, 169, 98, 0.3)" }}>
                                                          <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                            {element.oneMonth}
                                                          </Typography>
                                                        </Stack>
                                                      </>
                                                      :
                                                      <>
                                                        {
                                                          element.oneMonth === "D" ?
                                                            <>
                                                              <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(255, 103.06, 103.06, 0.40)" }}>
                                                                <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                                  {element.oneMonth}
                                                                </Typography>
                                                              </Stack>
                                                              <Stack sx={{ position: "absolute", top: "40px", left: "77px", bgcolor: "transparent" }} alignItems={"center"}>
                                                                <ReportProblemIcon sx={{ height: "17px", width: "21px", color: "yellow" }} />
                                                              </Stack>
                                                            </>
                                                            :
                                                            <>
                                                              {
                                                                element.oneMonth === "E" ?
                                                                  <>
                                                                    <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(255, 46.75, 46.75, 0.40)" }}>
                                                                      <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                                        {element.oneMonth}
                                                                      </Typography>
                                                                    </Stack>
                                                                    <Stack sx={{ position: "absolute", top: "40px", left: "77px", bgcolor: "transparent" }} alignItems={"center"}>
                                                                      <ReportProblemIcon sx={{ height: "17px", width: "21px", color: "yellow" }} />
                                                                    </Stack>
                                                                  </>
                                                                  :
                                                                  <>
                                                                    {
                                                                      element.oneMonth === "" ?
                                                                        <Typography align='center' sx={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: "12px", fontFamily: 'Roboto Regular', textTransform: 'none' }}>
                                                                          No Data
                                                                        </Typography>
                                                                        :
                                                                        ""
                                                                    }
                                                                  </>
                                                              }
                                                            </>
                                                        }
                                                      </>
                                                  }
                                                </>
                                            }
                                          </>
                                      }
                                    </Button>
                                  </Stack>
                                </td>
                                <td className="ThisYear">
                                  <Stack direction={"row"} sx={{ width: "100%", height: "100%", }} >
                                    <Button
                                      onClick={() => handleClickEmissionBoard(index, element.imo, element.Januarytonow_from, element.Januarytonow_to, "Monthly")}
                                      sx={{ width: "100%", height: "100%", ":hover": { bgcolor: "rgba(255, 255, 255, 0.5)", } }}
                                      disabled={element.Januarytonow === "" ? true : false}>
                                      {
                                        element.Januarytonow === "A" ?
                                          <>
                                            <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(0, 126, 58, 0.3)" }}>
                                              <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                {element.Januarytonow}
                                              </Typography>
                                            </Stack>
                                          </>
                                          :
                                          <>
                                            {
                                              element.Januarytonow === "B" ?
                                                <>
                                                  <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(39, 172, 101, 0.3)" }}>
                                                    <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                      {element.Januarytonow}
                                                    </Typography>
                                                  </Stack>
                                                </>
                                                :
                                                <>
                                                  {
                                                    element.Januarytonow === "C" ?
                                                      <>
                                                        <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(218, 169, 98, 0.3)" }}>
                                                          <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                            {element.Januarytonow}
                                                          </Typography>
                                                        </Stack>
                                                      </>
                                                      :
                                                      <>
                                                        {
                                                          element.Januarytonow === "D" ?
                                                            <>
                                                              <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(255, 103.06, 103.06, 0.40)" }}>
                                                                <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                                  {element.Januarytonow}
                                                                </Typography>
                                                              </Stack>
                                                              <Stack sx={{ position: "absolute", top: "40px", left: "77px", bgcolor: "transparent" }} alignItems={"center"}>
                                                                <ReportProblemIcon sx={{ height: "17px", width: "21px", color: "yellow" }} />
                                                              </Stack>
                                                            </>
                                                            :
                                                            <>
                                                              {
                                                                element.Januarytonow === "E" ?
                                                                  <>
                                                                    <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(255, 46.75, 46.75, 0.40)" }}>
                                                                      <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                                        {element.Januarytonow}
                                                                      </Typography>
                                                                    </Stack>
                                                                    <Stack sx={{ position: "absolute", top: "40px", left: "77px", bgcolor: "transparent" }} alignItems={"center"}>
                                                                      <ReportProblemIcon sx={{ height: "17px", width: "21px", color: "yellow" }} />
                                                                    </Stack>
                                                                  </>
                                                                  :
                                                                  <>
                                                                    {
                                                                      element.Januarytonow === "" ?
                                                                        <Typography align='center' sx={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: "12px", fontFamily: 'Roboto Regular', textTransform: 'none' }}>
                                                                          No Data
                                                                        </Typography>
                                                                        :
                                                                        ""
                                                                    }
                                                                  </>
                                                              }
                                                            </>
                                                        }
                                                      </>
                                                  }
                                                </>
                                            }
                                          </>
                                      }
                                    </Button>
                                  </Stack>
                                </td>
                                <td className="LastYear">
                                  <Stack direction={"row"} sx={{ width: "100%", height: "100%" }}>
                                    <Button
                                      onClick={() => handleClickEmissionBoard(index, element.imo, element.LastYear_from, element.LastYear_to, "Monthly")}
                                      sx={{ width: "100%", height: "100%", ":hover": { bgcolor: "rgba(255, 255, 255, 0.5)", } }}
                                      disabled={element.LastYear === "" ? true : false}>
                                      {
                                        element.LastYear === "A" ?
                                          <>
                                            <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(0, 126, 58, 0.3)" }}>
                                              <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                {element.LastYear}
                                              </Typography>
                                            </Stack>
                                          </>
                                          :
                                          <>
                                            {
                                              element.LastYear === "B" ?
                                                <>
                                                  <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(39, 172, 101, 0.3)" }}>
                                                    <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                      {element.LastYear}
                                                    </Typography>
                                                  </Stack>
                                                </>
                                                :
                                                <>
                                                  {
                                                    element.LastYear === "C" ?
                                                      <>
                                                        <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(218, 169, 98, 0.3)" }}>
                                                          <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                            {element.LastYear}
                                                          </Typography>
                                                        </Stack>
                                                      </>
                                                      :
                                                      <>
                                                        {
                                                          element.LastYear === "D" ?
                                                            <>
                                                              <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(255, 103.06, 103.06, 0.40)" }}>
                                                                <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                                  {element.LastYear}
                                                                </Typography>
                                                              </Stack>
                                                            </>
                                                            :
                                                            <>
                                                              {
                                                                element.LastYear === "E" ?
                                                                  <>
                                                                    <Stack justifyContent={"center"} sx={{ position: "absolute", top: "15px", left: "55px", width: "33px", height: "33px", bgcolor: "rgba(255, 46.75, 46.75, 0.40)" }}>
                                                                      <Typography align='center' sx={{ color: '#ffffff', fontSize: ciiSize, fontFamily: 'RobotoRegular' }}>
                                                                        {element.LastYear}
                                                                      </Typography>
                                                                    </Stack>
                                                                  </>
                                                                  :
                                                                  <>
                                                                    {
                                                                      element.LastYear === "" ?
                                                                        <Typography align='center' sx={{ color: 'rgba(255, 255, 255, 0.6)', fontSize: "12px", fontFamily: 'Roboto Regular', textTransform: 'none' }}>
                                                                          No Data
                                                                        </Typography>
                                                                        :
                                                                        ""
                                                                    }
                                                                  </>
                                                              }
                                                            </>
                                                        }
                                                      </>
                                                  }
                                                </>
                                            }
                                          </>
                                      }
                                    </Button>
                                  </Stack>
                                </td>
                                <td className="padding3">
                                  <span></span>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </>
                      :
                      ""
                    }
                  </tbody>
                  :
                  <Box>
                    {loading && (
                      <CircularProgress
                        size={68}
                        sx={{
                          color: "grey",
                          position: 'absolute',
                          top: 500,
                          left: 950,
                          zIndex: 1,
                        }}
                      />
                    )}
                  </Box>
                }
              </table>
            </div>
          </div>
        </Box>
      </Main >
    </Box >
  );
}
