import * as React from 'react';

import { FC, useEffect, useRef } from "react";
import L, { Icon } from "leaflet";
import { Marker, MarkerProps } from "react-leaflet";

// import iconShip from './icon-ship.png';
// import iconShipUnselected from './icon-ship-unselected.png';
import iconShip from '../../assets/images/icon-ship.png';
import iconShipUnselected from '../../assets/images/icon-ship-unselected.png';


type RotableMarkerProps = MarkerProps & {
    timestamp: any;
    plot: number;
    index: any;
    rotationAngle: number;
    onClickMap: any;
};

const merkerIcon1 = new Icon({
    iconUrl: iconShip,
    iconSize: [21, 21], //マーカーのサイズ
    iconAnchor: [10.5, 10.5], //中心位置(左上[0,0])
    className: "marker"
});
const merkerIcon2 = new Icon({
    iconUrl: iconShipUnselected,
    iconSize: [7, 21], //マーカーのサイズ
    iconAnchor: [3.5, 10.5], //中心位置(左上[0,0])
    className: "marker"
});

export const CustomMarker: FC<RotableMarkerProps> = (props) => {
    const { timestamp, plot, index, rotationAngle, onClickMap } = props;
    const markerRef = useRef<any>();

    useEffect(() => {
        let ignore = false;

        const marker = markerRef.current;

        if (marker) {
            const proto_setPos = marker._setPos;
            marker._setPos = (pos: any) => {
                proto_setPos.call(marker, pos);
                if (!ignore) {
                    if (rotationAngle) {
                        marker._icon.style[L.DomUtil.TRANSFORM + "Origin"] = 'center';
                        marker._icon.style[L.DomUtil.TRANSFORM] += "rotate(" + rotationAngle + "deg)";
                    }
                }
            };
            marker.update();
        }
        return () => {
            ignore = true;
        };
    }, [rotationAngle]);



    return (
        <>
            <Marker key={rotationAngle} ref={markerRef} {...props} icon={plot === index ? merkerIcon1 : merkerIcon2} zIndexOffset={plot === index ? 99 : 0} eventHandlers={{ click: () => onClickMap(plot, timestamp) }}>
                {props.children}
            </Marker>
        </>
    );
};
